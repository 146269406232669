//CardComponent.js

import React from 'react';
import { Card } from 'react-bootstrap';
import './News.css';

const CardComponent = ({ image, date, title, text, url, alt, photoCredit, topic, credit }) => (
  <Card className="card-hover d-flex flex-column h-100">
    <div className="aspect-ratio-16-9">
      <Card.Img
        variant="top"
        src={image}
        className="card-img-top"
        alt={alt}
      />
    </div>
    <Card.Body className="d-flex flex-column flex-grow-1">
      <table>
        <tbody>
          <tr>
            <td className="card-date">
              <Card.Title className="custom-card-text-left">{date}</Card.Title>
            </td>
            <Card.Title className="custom-card-text-right">{photoCredit}</Card.Title>
          </tr>
        </tbody>
      </table>
      <hr className="custom-card-divider" />
      <Card.Title className="custom-card-title">{title}</Card.Title>
      <hr className="custom-card-divider" />
      <Card.Text className="custom-card-text flex-grow-1">{text}</Card.Text>
      {url && (
        <Card.Text className="custom-card-text">
          <a href={url} target="_blank" rel="noopener noreferrer">
            {credit ? `${credit} -> Website` : '-> Website'}
          </a>
        </Card.Text>
      )}
    </Card.Body>
    <Card.Footer>
      {topic && <span className="card-topic">{topic}</span>}
    </Card.Footer>
  </Card>
);

export default CardComponent;